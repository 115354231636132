import React from 'react'
import Link from 'next/link'
import { HOME_PATH } from '../constants/paths'
import { Container, Grid, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRegion } from '../hooks/useRegion'

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      ...(await serverSideTranslations('en', ['common']))
    }
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(10, 3, 0, 3)
  },
  image: {
    marginBottom: theme.spacing(8)
  },
  imageContainer: {
    marginBottom: theme.spacing(3)
  },
  heading: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(3)
  },
  p: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    lineHeight: 1.5,
    marginBottom: theme.spacing(5)
  },
  button: {
    backgroundColor: '#081359',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '8px',
    padding: theme.spacing(1, 8),
    '&:hover': {
      boxShadow: '0px 3px 11px #08135929',
      backgroundColor: '#081359'
    },
    marginBottom: theme.spacing(20)
  }
}))

function NotFoundPage() {
  const { t } = useTranslation()
  const classes = useStyles()
  const region = useRegion()

  return (
    <>
      <Head>
        <title>404 not found - Live Klassisk</title>
        <meta name='robots' content='noindex' />
      </Head>
      <Grid container justifyContent='center'>
        <Container className={classes.container} maxWidth='sm'>
          <div className={classes.imageContainer}>
            <Image
              src='/images/icons8-bass_clef.svg'
              width={100}
              height={100}
              className={classes.image}
              alt='Sad bass clef'
            />
          </div>
          <Typography variant='h2' className={classes.heading}>
            404
          </Typography>
          <Typography variant='h2' className={classes.heading}>
            {t('You may have gone off on a tangent')}
          </Typography>
          <Typography variant='body1' className={classes.p}>
            {t(
              'The page you requested does not exist. Try going back to the home page.'
            )}
          </Typography>
          <Link href={HOME_PATH(region)} passHref>
            <Button className={classes.button}>{t('Home')}</Button>
          </Link>
        </Container>
      </Grid>
    </>
  )
}

export default NotFoundPage
